import React, { Component } from 'react';
import { getDatabase, ref, child, get, set } from "firebase/database";
import moment from 'moment';

class HappinessComponent extends Component {

  constructor(props) {
    super();
    this.props = props;
    this.state = {
      emotions: [
        {icon: "😭", score: 0, isSelected: false},
        {icon: "😢", score: 15, isSelected: false},
        {icon: "🙁", score: 40, isSelected: false},
        {icon: "😐", score: 50, isSelected: true},
        {icon: "🙂", score: 60, isSelected: false},
        {icon: "😀", score: 85, isSelected: false},
        {icon: "🥳", score: 100, isSelected: false}
      ],
      date: moment(),
      happiness: 50,
      highlights: ""
    };

  }


  handleHighlightsChange = (event) => {
    this.setState({ highlights: event.target.value });
    this.updateData()
  }

  onBackClicked = () => {
    this.props.notifyBackClicked();
  }

  componentDidMount = () => {
    this.getData();
  }

  getData = () => {

    let date = this.state.date.format("YYYY/MM/DD/")
    let databaseLink = `/${this.props.user.uid}/happiness/${date}`

    const dbRef = ref(getDatabase());
    get(child(dbRef, databaseLink))
    .then((snapshot) => {
      if (snapshot.exists()) {
        console.log("got data")
        this.parseData(snapshot.val())
      } else {
        console.log("No data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });

  }

  parseData = (data) => {
    this.setState({highlights: data.highlights})
    this.selectedHappiness(data.happiness)
  }

  selectedHappiness = (score) => {
    var emotions = this.state.emotions;

    emotions.forEach(emotion => {
      if (emotion.score === score) {
        emotion.isSelected = true
      } else {
        emotion.isSelected = false
      }

    })

    this.setState({emotions: emotions, happiness: score}, function() {
      this.updateData()
  })

  }

  updateData = () => {
    let date = this.state.date.format("YYYY/MM/DD/")
    let databaseLink = `/${this.props.user.uid}/happiness/${date}`

    const db = getDatabase();
    set(ref(db, databaseLink), {
        happiness: this.state.happiness,
        highlights: this.state.highlights
      });
  }

  render() {
    return (
      <div className="containerHappinessTracker">

        <div className="w-full h-16 lg:h-16 bg-slate-700 shadow-lg flex items-center flex-row">
          <div className="p-10 text-white" onClick={this.onBackClicked}>
            <span className="material-icons-outlined text-2xl">arrow_back</span>
          </div>
          <div className="p-10 text-white" onClick={this.onBackClicked}>Happiness Tracker</div>
        </div>

        <div className="flex flex-col items-center">
          <p className="text-5xl">{this.state.date.format('dddd')}</p>
          <p className="text-2xl">{this.state.date.format("MMMM Do YYYY")}</p>

          <div className="flex flex-row mt-10">


          {
            this.state.emotions.map( (item, index) => {

              var additionalClassName = ""

              if (index === 0) {
                additionalClassName = "rounded-tl-lg rounded-bl-lg"
              } else if (index === this.state.emotions.length -1) {
                additionalClassName = "rounded-tr-lg rounded-br-lg"
              }

              var background = "bg-slate-700"
              if (item.isSelected) {
                background = " bg-slate-900"
              }

              return <div
                        key={item.icon}
                        onClick={() => this.selectedHappiness(item.score)}
                        className={`py-4 lg:px-6 px-4 lg:text-2xl ${additionalClassName} ${background}`}>
                          {item.icon}
                      </div>
            })
          }

          </div>

          <div className="">

          <textarea
          onChange={this.handleHighlightsChange}
          className="mt-6 w-96 rounded-md border-slate-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          rows="5"
          value={this.state.highlights}
          ></textarea>

          </div>


        </div>
      </div>
    );
  }

}

export default HappinessComponent;
