import React, { Component } from 'react';
import { getDatabase, ref, child, get } from "firebase/database";

class BookListComponent extends Component {

  constructor(props) {
    super();
    this.props = props;
    this.state = {
      books: []
    };

  }

  componentDidMount = () => {
    this.getData();
  }

  onBackClicked = () => {
    this.props.notifyBackClicked();
  }

  getData = () => {
    var context = this;
    console.log("getting data...")

    const dbRef = ref(getDatabase());
    get(child(dbRef, `/${this.props.user.uid}/books/`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        console.log("got data..")
        context.setState({books: context.parseData(snapshot.val())})
      } else {
        console.log("No data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });

  }

  parseData = (data) => {

    let books = []
    for (let key in data) {
      books.push({
        key: key,
        title: data[key].title,
        author: data[key].author,
        colour: data[key].colour,
        icon: data[key].icon,
        year: data[key].year_read
      })
    }

    return books
  }

  render() {

    return (
      <div className="containerBookList">

        <div className="w-full h-16 lg:h-16 bg-slate-700 shadow-lg flex items-center flex-row">
          <div className="p-10 text-white" onClick={this.onBackClicked}>
            <span className="material-icons-outlined text-2xl">arrow_back</span>
          </div>
          <div className="p-10 text-white" onClick={this.onBackClicked}>Books</div>
        </div>

        <div className="flex flex-row flex-wrap gap-6 my-10 mx-10 justify-center">

        {
          this.state.books.map(item=> {

            return <div
              className="w-60 h-60 rounded-md flex items-center justify-between shadow-lg flex flex-col p-4"
              style={{"background-color": item.colour}}
              key={item.key}
            >
              <p className="px-4 bg-white rounded-md text-black text-xs text-center opacity-50">{item.year}</p>

              <p className="w-full my-4 text-white text-xs text-center">
                <span className="material-icons-outlined text-3xl">{item.icon}</span>
                </p>
              <p className="w-full text-white text-s text-center">{item.title}</p>
              <p className="w-full text-white text-s text-center opacity-70">{item.author}</p>

              <p className="px-4 mt-4 bg-white rounded-md text-black text-s text-center opacity-80">0 notes</p>

            </div>

          })
        }

        </div>

      </div>
    );
  }

}

export default BookListComponent;
