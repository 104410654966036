import React, { Component } from 'react';
import LoginComponent from '../login/LoginComponent.js'
import HomeComponent from '../home/HomeComponent.js'
import BookListComponent from '../books/BookListComponent.js'
import HappinessComponent from '../happiness/HappinessComponent.js'
import firebase from './../firebase.js';
import { getAuth, onAuthStateChanged } from "firebase/auth";


class RootComponent extends Component {

  constructor(props) {
    super();
    this.props = props;
    this.state = {
      user : undefined,
      screen: "home"
    }
  }

  componentDidMount() {
    this.authenticationListener();
  }

  authenticationListener = () => {
    const auth = getAuth(firebase);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.setState({user: user});
      } else {
          console.log("signed out")
          this.setState({user: undefined});
      }
    });
  }

  tapOnItem = (item) => {
    this.setState({screen: item})
  }

  goHome = () => {
    this.setState({screen: "home"})
  }

  render() {

    var rootElement = <LoginComponent />
    if (this.state.user) {

      if (this.state.screen === "home") {
        rootElement = <HomeComponent notifyTapOnItem={this.tapOnItem}/>
      } else if (this.state.screen === "books") {
        rootElement = <BookListComponent
                        notifyBackClicked={this.goHome}
                        user={this.state.user}
                        />
      } else if (this.state.screen === "happiness tracker") {
        rootElement = <HappinessComponent
                        notifyBackClicked={this.goHome}
                        user={this.state.user}
                        />
      } else {
        rootElement = <p>woops</p>
      }

    }

    return (
      <div className="containerRoot">
        {rootElement}
      </div>
    );
  }

}

export default RootComponent;
