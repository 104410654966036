import React, { Component } from 'react';
import firebase from './../firebase.js';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";


class LoginComponent extends Component {

  constructor(props) {
    super();
    this.props = props;
    this.state = {
      username: "",
      password: ""
    };
  }

  handleUsernameChange = (event) => {
    this.setState({ username: event.target.value });
  }

  handlePasswordChange = (event) => {
   this.setState({ password: event.target.value });
 }

 handleLogin = () => {
   const auth = getAuth(firebase);
    signInWithEmailAndPassword(auth, this.state.username, this.state.password)
      .then((userCredential) => {
        // Signed in
        console.log("signed in")
      })
      .catch((error) => {
        console.log("ERROR");
        console.log(error)
      });
 }



  render() {

    return (
      <div className="h-screen">
        <div className="flex lg:flex-row flex-col-reverse">

          <div className="lg:w-1/2 w-full">
            <div className="lg:mx-20 lg:px-20 mx-5 px-5 my-10 grid grid-cols-1 gap-6">
              <p className="text-5xl">Hello</p>
              <label className="block">
                      <span className="text-gray-700">email</span>
                      <input
                        type="text"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        onChange={this.handleUsernameChange}
                        placeholder="email"/>
                    </label>

            <label className="block">
                    <span className="text-gray-700">password</span>
                    <input
                      type="password"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      onChange={this.handlePasswordChange}
                      placeholder="password"/>
                  </label>

              <span>
                <button
                className="px-4 py-2 font-semibold bg-rose-500 text-white rounded-md shadow-sm w-full"
                onClick={this.handleLogin}
                >login</button>
              </span>
            </div>
          </div>

          <div className="lg:w-1/2 w-full lg:h-screen bg-slate-100 p-10 flex justify-center">
            <p><span className="material-icons-outlined text-5xl lg:text-7xl lg:my-30 p-10 bg-white text-slate-700 rounded-full drop-shadow-xl">emoji_people</span></p>
          </div>

        </div>
      </div>
    );
  }

}

export default LoginComponent;
