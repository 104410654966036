import { initializeApp } from "firebase/app";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAM_t7XNENW9Wqf7ZTvu1zIUHRe_XT85Lk",
  authDomain: "danielle-me.firebaseapp.com",
  projectId: "danielle-me",
  storageBucket: "danielle-me.appspot.com",
  messagingSenderId: "593042257945",
  appId: "1:593042257945:web:4db1c13dfd567289b11288",
  measurementId: "G-R9G7C2S1JF",
  databaseURL: "https://danielle-me-default-rtdb.europe-west1.firebasedatabase.app",
};

// Initialize Firebase
var firebase = initializeApp(firebaseConfig);
export default firebase;
