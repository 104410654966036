import React, { Component } from 'react';


class HomeComponent extends Component {

  constructor(props) {
    super();
    this.props = props;
    this.state = {
        icons : [
          {icon:"sentiment_satisfied", title:"happiness tracker", enabled:true},
          {icon:"memory", title:"stitch counter", enabled:false},
          {icon:"book", title:"books", enabled:true}
        ]
    };

  }

  tapItem = (item) => {
    this.props.notifyTapOnItem(item)
  }

  render() {

    return (
      <div className="containerHome">

      <div className="w-full h-16 lg:h-24 bg-slate-700 shadow-lg flex items-center justify-center">
        <p className="w-full text-white text-xl lg:text-2xl text-center">hello danielle</p>
      </div>

        <div className="flex flex-row flex-wrap gap-6 my-10 mx-10 justify-center">

          {
            this.state.icons.map(item=> {
              var background = "bg-rose-200"
              if (item.enabled) {
                background = "bg-rose-500"
              }

              let containerClassName = `w-28 h-24 ${background} rounded-md flex items-center justify-center shadow-lg flex flex-col p-4`

              return <div
                className={containerClassName}
                onClick={() => this.tapItem(item.title)}
                key={item.title}
              >
                <p className="w-full text-white text-xs text-center"><span className="material-icons-outlined text-3xl">{item.icon}</span></p>
                <p className="w-full text-white text-xs text-center">{item.title}</p>
              </div>


            })

          }
        </div>

      </div>
    );
  }

}

export default HomeComponent;
